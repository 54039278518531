/* #comparision .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: rgb(7 103 123 / 27%);
    color: #fff;
} */

#comparision .ant-table-cell {
    background: #181b20;
}

.custom-border-comparison-table .ant-table-bordered>.ant-table-container {
    border: none !important;
}

.custom-border-comparison-table td.ant-table-cell {
    font-size: 14px;
}

#comparision .ant-table-thead>tr>th:first-child,
#comparision .ant-table-tbody>tr>td:first-child {
    background: #181b20;
    padding-left: 24px !important;
}

#comparision .ant-table-thead>tr>th.pai-table-cell {
    color: #a7a7a7 !important;
}

.custom-border-comparison-table .ant-table-bordered table,
.custom-border-comparison-table .ant-table-bordered tbody,
.custom-border-comparison-table .ant-table-bordered td,
.custom-border-comparison-table .ant-table-bordered tfoot,
.custom-border-comparison-table .ant-table-bordered th,
.custom-border-comparison-table .ant-table-bordered thead,
.custom-border-comparison-table .ant-table-bordered tr {
    border-color: #111419 !important;
    border-width: 3px !important;
}

.comparision-table-cell-text {
    margin: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 100%;
    text-align: center;
}

.comparision-table-cell {
    background: rgba(255, 255, 255, 0.03);
    border: 3px solid #111419;
}

.comparision-table-cell .ant-space-item{
    width: inherit;
}

.comparision-table-carousel .slick-list {
    padding: 0px !important;
}

.comparision-table-carousel .slick-disabled,
.comparision-table-carousel .slick-next,
.comparision-table-carousel .slick-prev {
    position: absolute;
    top: 5%;
    color: #15AFD0 !important;
}

.comparision-table-carousel .slick-slide {
    padding: 0px !important;
}

/* .comparision-table-carousel-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
} */