.two-by-two-chart-summary-view .data-label > tspan {
  fill: white !important;
}

.two-by-two-chart-summary-view .root-default > div {
  border-color: rgb(48, 48, 48);
}

.two-by-two-chart-summary-view .root-default > div > div > svg > g > line {
  stroke: rgb(48, 48, 48) !important;
}

.two-by-two-chart-summary-view .tooltip {
  background-color: #111419;
  box-sizing: border-box;
  box-shadow: 20px;
}

.two-by-two-chart-summary-view .x-axis-group-component > text > tspan {
  fill: rgb(100, 100, 100) !important;
}

.two-by-two-chart-summary-view .y-axis-group-component > text > tspan {
  fill: rgb(100, 100, 100) !important;
}
