.photo-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 16px;
  }
  
  .photo-grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .photo-grid-item > div{
    height:100%;
  }
  
  .photo-grid-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .photo-grid-item.landscape img {
    height: 100%;
    width: auto;
  }
  
  .photo-grid-item.portrait {
    grid-column: span 1;
  }
  
  .photo-grid-item.landscape {
    grid-column: span 2;
  }