.pk-component > textarea {
  height: 20vh;
}

.pai-editable-formula-component > div > div span {
  visibility: visible !important;
  color: white;
}

.pai-entity-aggregation {
  /* position: relative; */
  /* z-index: 1; */
  color: #8fd4e4;
  /* color: aqua; */
  /* text-shadow: 1px 1px 1px #181b20, 1px -1px 1px #181b20, -1px 1px 1px #181b20,
    -1px -1px 1px #181b20; */
  /* pointer-events: none; */
}

.pai-entity-fields {
  /* position: relative; */
  /* z-index: 1; */
  color: #f7b844;
  /* color: yellow; */
  /* text-shadow: 1px 1px 1px #181b20, 1px -1px 1px #181b20, -1px 1px 1px #181b20,
    -1px -1px 1px #181b20; */
  /* pointer-events: none; */
}

.predefined-collapse-item {
  cursor: not-allowed !important;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  height: 30px;
  padding-left: 2px;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(128,128,128,0.2); */
}

.predefined-collapse-item:hover {
  background-color: #05242b;
}

.kpi-accordion-item{
  padding-left: 5%;
  color: rgba(255, 255, 255,0.6);
}

.instruction-container {
  position: absolute;
  bottom: 0px;
}
