.login-component-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .login-shadow-border {
    padding: 40px;
    border-radius: 10px;
    border: 1px solid gainsboro;
    box-shadow: 5px 5px 17px 9px #ebebeb;
  }
  