.section-select-option .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;
}

.section-select-switch-container {
    display: inline-flex;
    align-items: center;
}

.section-select-switch-label {
    margin-right: 4px;
}