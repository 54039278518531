.basicComponent-card-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.basicComponent-card-section:hover {
    /* background: linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148)) !important; */
    background-color: rgba(21, 175, 208, 0.2) !important;
}