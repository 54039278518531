.configurable-ui-management__reports__actions {
  color: rgb(21, 175, 208);
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: nowrap;
}

.configurable-ui-management__reports__delete-icon {
  margin-left: 4px;
}