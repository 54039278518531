.correlation-chart-detailed-view .header-name-cell-text {
  display: flex !important;
  justify-content: center !important;
  color: white !important;
}

.correlation-chart-detailed-view .row-name-cell > span {
  font-size: 14px !important;
  color: white !important;
}

.correlation-chart-detailed-view .cell {
  font-size: 14px !important;
}

.correlation-chart-detailed-view .empty-cell {
  background: #181b20;
}

.correlation-chart-detailed-view .body > div > div {
  margin-top: 0px !important;
}
