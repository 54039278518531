.pai-form-lib-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  margin-bottom: 24px;
  vertical-align: top;
  /* align-items: end; */
}

.pai-form-lib-item-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 22px !important;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px !important;
}
