/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* ********************************************** */

.customSider {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.custom-scroll::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custom-scroll::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background-color: #15afd0;
}

.hover-tile:hover {
  border: 1px solid #1890ff2e;
  /*background:rgba(255,255,255,0.09)!important;*/
  cursor: pointer;
  transform: scale(1.02);
}

.hover-tile {
  transition: transform ease 200ms;
}

.content-section:hover {
  margin-right: 10px;
}

.custom-scroll {
  overflow-x: hidden;
  overflow-y: hidden;
}

.custom-scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.custom-scroll-x:hover {
  overflow-x: auto;
}

.custom-scroll-y:hover {
  overflow-y: auto;
}

/* .xai-row {
  position: relative;
} */

/* .ant-btn {
  min-width: 100px;
} */

.tile-right,
.active-tile {
  position: relative;
}

.tile-right:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 25px;
  background: rgba(24, 144, 255, 0.32);
}

.active-tile:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 15px;
  height: 1px;
  background: rgba(24, 144, 255, 0.32);
}

.cell {
  stroke: #181b20;
  stroke-width: 1px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.cell:hover {
  stroke: rgba(255, 255, 255, 0.05);
  z-index: 2;
}

.cell2 {
  stroke: rgba(255, 255, 255, 0.08);
  stroke-width: 1px;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.textLabel {
  color: #fff;
  opacity: 0.15;
  font-size: 64px;
  font-weight: bold;
}

.group {
  stroke-width: 2px;
}

.label {
  opacity: 0;
  text-anchor: middle;
  fill: white;
}

.label > .name {
  dominant-baseline: text-after-edge;
  font-size: 8px;
}

#voronoi-chart {
  padding: 20px 30px;
}

table {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.hover-button {
  opacity: 0.9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.hover-button:hover {
  opacity: 1;
}

.custom-tabs .xai-tabs-nav .xai-tabs-tab-active {
  border-bottom: 0px;
  font-weight: normal;
}

.custom-tabs .xai-tabs-bar.xai-tabs-top-bar {
  border-bottom: 0px solid rgba(255, 255, 255, 0.1);
  border-top: 0px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
}

.pulse-button {
  animation: shadow-pulse 2s infinite;
}

.untouchable {
  pointer-events: none;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px #13839c73;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

.highlighted {
  box-shadow: 0 0 10px #15c4eccc;
}

.hover-section:hover {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
}

html {
  zoom: 0.9;
}
body {
  padding: 0;
  margin: 0;
  font-family: SF Display;
}

body > iframe {
  /* Added to avoid iframe isse on CRA */
  pointer-events: none;
}

.activeSubMenu p {
  color: #15afd0 !important;
}

.activeMenu {
  border-right: 2px solid #15afd0 !important;
  background: #181b20 !important;
}

.activeMenu p {
  color: #fff !important;
}

.custom-radio .xai-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

ul.line-list {
  list-style-type: none;
  padding-left: 20px;
}
ul.line-list > li {
  text-indent: -10px;
}
ul.line-list > li:before {
  content: "-";
  text-indent: -10px;
  margin-right: 10px;
}

.hvt-cell:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

@keyframes roadRunnerIn {
  0% {
    transform: translateX(-1500px) skewX(30deg) scaleX(1.3);
  }
  70% {
    transform: translateX(30px) skewX(0deg) scaleX(0.9);
  }
  100% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flicker {
  0% {
    fill: #fff;
  }
  50% {
    fill: #000;
  }
  100% {
    fill: #fff;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #15afd0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #15afd0;
}

.List::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
/* Rishabh Tailwind CSS placeholder classes */
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-10px {
  margin-bottom: 10px;
}
.m-0 {
  margin: 0px !important;
}
.flex {
  display: flex;
}
.absolute {
  position: absolute;
}
.pos-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.relative {
  position: relative;
}
.grid {
  display: grid;
}
.min-h-screen {
  min-height: 100vh;
}
.w-64 {
  width: 16rem;
}
.w-6\/12 {
  width: 50%;
}
.w-6\/10 {
  width: 80%;
}
.w-4\/10 {
  width: 80%;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.h-3vh {
  height: 3vh;
}
.h-5vh {
  height: 5vh;
}
.h-screen\/2 {
  height: 50vh;
}
.transform-1\.32 {
  transform: scale(1.32);
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(1, minmax(0, 3fr));
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.gap-4 {
  gap: 1rem;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.border-0 {
  border-width: 0;
}
.border {
  border-width: 1px;
}
.border-r {
  border-right-width: 1px;
}
.border-r-0 {
  border-right-width: 0;
}
.border-t-0 {
  border-top-width: 0;
}
.border-b-0 {
  border-bottom-width: 0;
}
.border-solid {
  border-style: solid;
}
.border-primary {
  --tw-border-opacity: 1;
  border-color: rgba(21, 175, 208, var(--tw-border-opacity));
}
.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.w-16\/100 {
  width: 16%;
}
.w-42\/100 {
  width: 42%;
}
.max-w-16\/100 {
  max-width: 16% !important;
}
.max-w-42\/100 {
  max-width: 42% !important;
}
.p-2 {
  padding: 0.5rem;
}
.p-8 {
  padding: 1rem;
}
.pt-1 {
  padding-top: 10px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-2 {
  padding-bottom: 20px;
}
.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.pl-5 {
  padding-left: 1.25rem;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-white-50 {
  --tw-text-opacity: 0.5;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-primary {
  --tw-text-opacity: 1;
  color: rgba(21, 175, 208, var(--tw-text-opacity));
}
.font-sf-display {
  font-family: SF Display;
}
.font-normal {
  font-weight: 400;
}
.text-xs {
  font-size: 12px;
}
.text-sm {
  font-size: 13px;
  line-height: 15px;
}
.text-base {
  font-size: 15px;
}
.underline {
  text-decoration: underline;
}
.text-primary {
  color: #15afd0 !important;
}
.text-gray {
  color: hsla(0, 0%, 100%, 0.02) !important;
}
.border-primary {
  border-color: #15afd0 !important;
}
.grid-custom-wrap {
  grid-template-columns: repeat(1, minmax(0, -webkit-max-content));
  grid-template-columns: repeat(1, minmax(0, max-content));
}

.whitespace-nowrap {
  white-space: nowrap;
}

.custom-input {
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.02) !important;
}

.custom-search input {
  border: 1px solid #434343;
  outline: none;
  background: #111419 !important;
}

.custom-search .xai-input-search-button {
  min-width: 30px;
  background: #111419 !important;
}

.custom-input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.custom-panel {
  background-color: #181b20;
  padding: 0.5rem 1rem;
  border-radius: 10px !important;
}

.pointer {
  cursor: pointer;
}

.xai-collapse-borderless > .xai-collapse-item {
  border-bottom: 2px solid #111419;
}

.xai-collapse:not(.xai-collapse-icon-position-left)
  > .xai-collapse-item
  > .xai-collapse-header
  .xai-collapse-arrow {
  left: auto;
  right: 16px;
}

.xai-collapse:not(.xai-collapse-icon-position-left)
  > .xai-collapse-item
  > .xai-collapse-header {
  padding: 8px 17px;
}

.xai-collapse > .xai-collapse-item > .xai-collapse-header {
  padding-top: 0px;
  padding-bottom: 0px;
}

.xai-collapse.xai-collapse-icon-position-left
  > .xai-collapse-item
  > .xai-collapse-content
  > .xai-collapse-content-box {
  padding: 0px;
}

.xai-collapse-header {
  font-size: 16px;
  padding: 6px;
  padding-left: 0.5rem;
}

.xai-collapse-borderless > .xai-collapse-item {
  border-bottom: 2px solid #111419;
}

.xai-collapse:not(.xai-collapse-icon-position-left)
  > .xai-collapse-item
  > .xai-collapse-header
  .xai-collapse-arrow {
  left: auto;
  right: 16px;
}

.xai-collapse:not(.xai-collapse-icon-position-left)
  > .xai-collapse-item
  > .xai-collapse-header {
  padding: 8px 17px;
}

.xai-collapse > .xai-collapse-item > .xai-collapse-header {
  padding-top: 0px;
  padding-bottom: 0px;
}

.xai-collapse.xai-collapse-icon-position-left
  > .xai-collapse-item
  > .xai-collapse-content
  > .xai-collapse-content-box {
  padding: 0px;
}

.xai-collapse-borderless {
  background-color: transparent !important;
}

.xai-collapse-header {
  font-size: 16px;
  padding: 6px;
  padding-left: 0.5rem;
}

.xai-input-group-addon {
  padding: 0px !important;
  border: 0px !important;
}
.xai-input-search
  > .xai-input-group
  > .xai-input-group-addon:last-child
  .xai-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 10px 10px 0;
}

.min-w-100 {
  min-width: 100px;
}

.flexGrow-1 {
  flex-grow: 1;
}

.ant-card-meta {
  display: block !important;
}

/* This Class is added for using Text Highlighters throughout the Application */
.custom_textHighlighter {
  background-color: #15afd0 !important;
  color: #000;
}

/* Metric UI Library Components Custom classes  */
.modelMetric-title {
  margin: 0px 0px 3px;
  line-height: 101%;
  display: block;
  font-size: 12px !important;
}

.modelMetric-subTitle {
  line-height: 22px;
  padding-top: 6px;
  margin: 0px;
  font-size: 14px !important;
  color: rgb(255, 255, 255) !important;
}

.opacity-0-55 {
  opacity: 0.55;
}

svg.blue {
  fill: #15afd0;
}

/* Common Class for Styling viz - table with P.AI Theme  */
/* #region */
.common-table-class
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  background: #181b20;
}

.common-table-class td.ant-table-cell {
  border-top: 1px solid #ffffff12 !important;
  border-bottom: 1px solid #ffffff12 !important;
  border-left: 1px solid #ffffff12 !important;
  border-right: 1px solid #ffffff12 !important;
  padding: 8px !important;
  /* font-size: 14px; */
}

.common-table-class th.ant-table-cell {
  color: #15afd0 !important;

  border-bottom: 2px solid #ffffff12 !important;
  border-left: 2px solid #ffffff12 !important;
  text-align: center !important;
  padding: 8px !important;
}

.common-table-class .ant-table table {
  border-radius: 0px !important;
}


/* #endregion */