.overview-kpi-card-section .cs-root {
  height: 17vh;
  width: 100%;
}
.overview-kpi-card-section .cs-title {
  font-size: 1.5rem;
}

.overview-kpi-card-section .cs-subTitle {
  font-size: 0.813rem;
}

.overview-kpi-card-section .ant-card-body {
  height: 100%;
}
