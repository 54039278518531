/* #comparision .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: rgb(7 103 123 / 27%);
  color: #fff;
} */

#comparision .ant-table-cell {
  background: #181b20;
}

.custom-border-comparison-table .ant-table-bordered > .ant-table-container {
  border: none !important;
}

#comparision .ant-table-thead > tr > th:first-child,
#comparision .ant-table-tbody > tr > td:first-child {
  background: #181b20;
  padding-left: 24px !important;
}

#comparision .ant-table-thead > tr > th.pai-table-cell {
  color: #a7a7a7 !important;
}

.custom-border-comparison-table .ant-table-thead th {
  background: #181B20;
}
/* .custom-border-comparison-table .ant-table-bordered table,
.custom-border-comparison-table .ant-table-bordered tbody,
.custom-border-comparison-table .ant-table-bordered td,
.custom-border-comparison-table .ant-table-bordered tfoot,
.custom-border-comparison-table .ant-table-bordered th,
.custom-border-comparison-table .ant-table-bordered thead,
.custom-border-comparison-table .ant-table-bordered tr {
  border-color: #111419 !important;
  border-width: 3px !important;
} */

.custom-border-comparison-table .ant-table-thead th,
.custom-border-comparison-table .ant-table-tbody td {
  border-color: #111419 !important;
  border-width: 3px !important;
}

.custom-border-comparison-table .ant-table-container .ant-table-body{
  overflow: hidden !important;
}

.custom-border-comparison-table .ant-table-sticky-holder{
  top: -20px !important;
}