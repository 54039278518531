.pai-table-body {
  margin: 0px !important;
}

.pai-table {
  background: transparent;
}

.pai-modal-close {
  display: none;
}

.pai-table-thead > tr > th {
  background: transparent !important;
  color: #fff !important;
  vertical-align: middle;
  height: 30px;
}

.pai-table-small {
  border: 0px !important;
  border-radius: 0px;
}

.pai-table-thead > tr > th {
  border-bottom: 1px solid rgb(24, 27, 32);
  color: #15afd0 !important;
  border-right: 0px solid rgba(255, 255, 255, 0.1);
}

.pai-table-tbody > tr:nth-child(odd) > td {
  /*background-color: rgb(24, 27, 32)!important;*/
}

.pai-table-thead > tr > th:last-child,
.pai-table-tbody > tr > td:last-child {
  padding-right: 0px !important;
}

.pai-table-tbody > tr > td {
  border-bottom: 0px solid rgba(255, 255, 255, 0.1);
  border-right: 0px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.75);
}

.pai-table-small
  > .pai-table-content
  > .pai-table-header
  > table
  > .pai-table-thead
  > tr
  > th,
.pai-table-small
  > .pai-table-content
  > .pai-table-body
  > table
  > .pai-table-thead
  > tr
  > th,
.pai-table-small
  > .pai-table-content
  > .pai-table-scroll
  > .pai-table-header
  > table
  > .pai-table-thead
  > tr
  > th,
.pai-table-small
  > .pai-table-content
  > .pai-table-scroll
  > .pai-table-body
  > table
  > .pai-table-thead
  > tr
  > th,
.pai-table-small
  > .pai-table-content
  > .pai-table-fixed-left
  > .pai-table-header
  > table
  > .pai-table-thead
  > tr
  > th,
.pai-table-small
  > .pai-table-content
  > .pai-table-fixed-right
  > .pai-table-header
  > table
  > .pai-table-thead
  > tr
  > th,
.pai-table-small
  > .pai-table-content
  > .pai-table-fixed-left
  > .pai-table-body-outer
  > .pai-table-body-inner
  > table
  > .pai-table-thead
  > tr
  > th,
.pai-table-small
  > .pai-table-content
  > .pai-table-fixed-right
  > .pai-table-body-outer
  > .pai-table-body-inner
  > table
  > .pai-table-thead
  > tr
  > th,
.pai-table-small
  > .pai-table-content
  > .pai-table-header
  > table
  > .pai-table-tbody
  > tr
  > td,
.pai-table-small
  > .pai-table-content
  > .pai-table-body
  > table
  > .pai-table-tbody
  > tr
  > td,
.pai-table-small
  > .pai-table-content
  > .pai-table-scroll
  > .pai-table-header
  > table
  > .pai-table-tbody
  > tr
  > td,
.pai-table-small
  > .pai-table-content
  > .pai-table-scroll
  > .pai-table-body
  > table
  > .pai-table-tbody
  > tr
  > td,
.pai-table-small
  > .pai-table-content
  > .pai-table-fixed-left
  > .pai-table-header
  > table
  > .pai-table-tbody
  > tr
  > td,
.pai-table-small
  > .pai-table-content
  > .pai-table-fixed-right
  > .pai-table-header
  > table
  > .pai-table-tbody
  > tr
  > td,
.pai-table-small
  > .pai-table-content
  > .pai-table-fixed-left
  > .pai-table-body-outer
  > .pai-table-body-inner
  > table
  > .pai-table-tbody
  > tr
  > td,
.pai-table-small
  > .pai-table-content
  > .pai-table-fixed-right
  > .pai-table-body-outer
  > .pai-table-body-inner
  > table
  > .pai-table-tbody
  > tr
  > td {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  font-size: 12px;
}

.pai-table-thead > tr.pai-table-row-hover:not(.pai-table-expanded-row) > td,
.pai-table-tbody > tr.pai-table-row-hover:not(.pai-table-expanded-row) > td,
.pai-table-thead > tr:hover:not(.pai-table-expanded-row) > td,
.pai-table-tbody > tr:hover:not(.pai-table-expanded-row) > td {
  background: #15afd045;
  color: #fff;
}

.pai-table-fixed-header
  > .pai-table-content
  > .pai-table-scroll
  > .pai-table-body {
  background: transparent;
}

.pai-table-small > .pai-table-content .pai-table-header {
  background: transparent;
}

.custom-table-review .pai-table table th.pai-table-cell {
  color: #15afd0 !important;
}

.custom-table-review td,
.custom-table-review th {
  border-bottom: 1px solid #303030 !important;
  padding-left: 15px!important;
  padding-right: 15px!important;
}
