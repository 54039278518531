.kpi-formula-wrapper {
  padding: 2%;
  border: 1px solid rgb(58, 57, 57);
  border-radius: 5px;
}
.kpi-select {
  margin: 0px 5px 0px 5px;
  width: 103px !important;
}
.kpi-selector-default > .ant-select-selector {
  background-color: black !important;
  border-color: #15afd0 !important;
  border-radius: 50px !important;
}

.kpi-selector-default
  > .ant-select-selector
  > .ant-select-selection-placeholder {
  color: #15afd0 !important;
  text-align: center;
}
.kpi-selector-default > .ant-select-selector > .ant-select-selection-item {
  color: #15afd0 !important;
  text-align: center;
}

.kpi-selector-selected > .ant-select-selector {
  background: #1a291c !important;
  border-color: #294e1e !important;
  color: #49ab1b !important;
  border-radius: 50px !important;
}

.kpi-selector-selected
  > .ant-select-selector
  > .ant-select-selection-placeholder {
  color: #49ab1b !important;
  background: #1a291c;
  border-color: #294e1e;
  text-align: center;
}

.kpi-tag-default {
  background: rgb(0, 0, 0);
  color: rgb(21, 175, 208);
  border: 1px solid rgb(21, 175, 208);
  margin: 0px 10px 0px 10px;
}

.kpi-tag-selected {
  color: #49ab1b;
  background: #1a291c;
  border-color: #294e1e;
  margin: 0px 10px 0px 10px;
}

.variant {
  margin-top: 20px;
  color: silver;
}

.numerator {
  text-align: center;
  padding-left: 12%;
  display: flex;
  justify-content: center;
}

.denominator {
  border-top: 1px solid grey;
  margin-top: 3%;
  padding: 3% 0% 0 0;
  display: flex;
  justify-content: center;
}
.operator {
  font-size: 2em;
  margin-left: 10px;
  margin-right: 10px;
}
.formula-statics {
  font-size: 2em;
}
.line {
  display: flex;
  justify-content: space-evenly;
}

.expression-var-container {
  display: flex;
  min-width: 10vw;
  justify-content: center;
}
