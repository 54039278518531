.recomm-results-tag-custom {
  display: flex;
  zoom: 0.75;
}

.recommendation-results-analysis-list .ant-card-head {
  margin: 0px !important;
  /* min-height: 80px; */
  padding:0 5px 0 15px !important; 
}

.recommendation-results-analysis-list .ant-card-body {
  height: calc(100% - 95px);
  padding-bottom: 15px;
}

.recommendation-results-analysis-list .ant-list {
  height: 100%;
}

.recommendation-results-analysis-list .ant-spin-nested-loading {
  height: 100%;
}

.recommendation-results-analysis-list .ant-spin-container {
  height: 100%;
}

.recommendation-results-analysis-list .ant-list-items {
  overflow-y: scroll;
  height: 100%;
}

.experiments-list-item:hover {
  background-color: rgb(18, 125, 148,0.15);
}

.highlighted-clickable {
  color: rgba(255, 255, 255, 0.84) !important;
  background-color: rgb(18, 125, 148,0.5) !important;
  cursor: pointer;
}