.api-scroller .ant-card-head-title {
  padding: 0px;
}

.api-scroller-wrapper {
  width: 100%;
  overflow: hidden;
}

.api-scroller {
  display: flex;
  width: 99vw;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.api-scroller::-webkit-scrollbar {
  display: none;
}

.arrow-button:hover {
  background-color: #18434f;
  transition-delay: 100ms;
  transition: ease-in-out;
}

.product-items-custom-wrapper {
  height: 30px;
  padding: 4px 0px;
}



.slick-arrow.slick-next {
  right: 20px !important;
}

.slick-arrow.slick-prev {
  left: -10px !important;
}

.slick-arrow {
  margin-top: -10px !important;
  z-index: 99;
}

.slick-arrow::before {
  background-color: rgba(0, 0, 0, 0.75);
  width: 60px;
  height: 60px;
  border-radius: 30px;
  font-size: 32px;
  line-height: 60px;
  display: block;
  color: #ffffffaa;
}

/* .custom-products-wrapper .slick-slide {
      display: flex !important;
  } */

/* .custom-products-wrapper .slick-list{
      display: grid !important;
  } */






/* 
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
  }
  
  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: 10px;
    z-index: 2;
    color: white;
  }
  
  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: 10px;
    z-index: 2;
    color: white;
  } */
.summary-container .ant-collapse-header {
  display: flex;
}

.summary-container .ant-collapse-arrow {
  align-self: center !important;
}

.panel-custom-header-div {
  display: flex;
  gap: 5px;
}

.summary-container .site-collapse-custom-panel {
  background-color: hsla(0, 0%, 100%, 0.04) !important;
  border-radius: 0px !important;
  padding: 10px
}

.summary-container .ant-collapse-item {
  border-radius: 0px !important;
  border-bottom: 2px solid #111419 !important;
}

.summary-container .ant-collapse-header {

  height: 40px !important;
  margin-bottom: 5px;
}

.summary-container .ant-table {
  background-color: hsla(0, 0%, 100%, 0);
  margin-left: 20px;
}

.summary-container .xai-table-row {
  background: #212429;
  border-bottom: #212429;
}

.summary-container .xai-table-tbody > tr > td{
  border-bottom: #212429;
}

.summary-container .xai-table-tbody > tr > td.xai-table-cell-row-hover {
   background: #18434f !important; 
}