.experiment-config-section {
    overflow-y: auto !important;
    height: 74vh;
}

.experiment-config-section .ant-collapse-expand-icon {
    margin: auto;
}

.experiment-config-section .ant-collapse {
    border: none;
    margin-bottom: 5px;
    background-color: #212429;
    padding: 10px 20px;
}

.experiment-config-section .ant-collapse-content>.ant-collapse-content-box {
    background-color: #212429;
    border: 0;
    /* overflow-y: scroll; */
}

.experiment-config-section .ant-divider-horizontal {
    margin: 0px;
}

.experiment-config-section .ant-row-start {
    padding: 0px 10px;
}

.experiment-config-section .row-section:hover {
    background-color: #18434f;
}