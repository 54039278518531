.access-control-viz-table .ant-table-header .ant-table-cell {
  background-color: transparent;
  color: #15afd0;
  font-size: 14px;
}

.access-control-viz-table .ant-table-tbody > tr.ant-table-row:hover > td,
.access-control-viz-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: rgba(21, 175, 208, 0.27058823529411763);
  color: #fff;
}

.access-control-viz-table .ant-table {
  font-size: 14px;
}

.access-control-viz-table .ant-spin-spinning > div {
  width: 100% !important;

  left: 0 !important;

  top: 10% !important;
}
