.configurable-ui-management-table, .configurable-ui-management-table__loading {
  height: 340px;
}

.configurable-ui-management-table {
  overflow: auto;
}

.configurable-ui-management-table__loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.configurable-ui-management-table__row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #434343;
}

.configurable-ui-management-table__row__cell {
  padding: 8px;
}