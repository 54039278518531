.modelMetric-title {
    margin: 0px 0px 3px;
    line-height: 101%;
    display: block;
    text-align: left;
    font-size: 12px !important;
}

.modelMetric-subTitle {
    line-height: 22px;
    padding-top: 6px;
    margin: 0px;
    font-size: 16px !important;
    color: rgb(255, 255, 255) !important;
    text-align: left;
}

.modelMetric-title-right {
    margin: 0px 0px 3px;
    line-height: 101%;
    display: block;
    text-align: right;
    font-size: 12px !important;
}

.modelMetric-subTitle-right {
    line-height: 22px;
    padding-top: 6px;
    margin: 0px;
    font-size: 16px !important;
    color: rgb(255, 255, 255) !important;
    line-height: 100%;
    text-align: right;
}
.calender-modal-wrapper .ant-picker-panel-container {
    box-shadow: none;
  }

  .source-info-wrapper {
    min-height: 500px;
  }

  .modal-calendar{
      width:200px
  }