.test-form-custom-new-div .ant-steps-item-active .ant-steps-item-title {
  color: #15afd0 !important;
  font-weight: 600;
  font-size: 16px;
}

.traversed-step .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.85) !important;
}

.traversed-step .ant-steps-item-icon {
  color: rgba(255, 255, 255, 0.85) !important;
}

.invalid-step-custom-wrapper .ant-steps-item-title {
    color: #faad14 !important;
  }
  
  .invalid-step-custom-wrapper .ant-steps-item-icon {
    color: #faad14 !important;
  }
