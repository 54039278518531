/* .template-container {
  max-height: 95vh;
  overflow-y: scroll;
} */

.hovercard .ant-card {
  /*background: linear-gradient(135deg, #1d2025, black);*/
  border-radius: 0px !important;
}

.hovercard {
  transition: transform 500ms;
  border-radius: 0px;
  /*box-shadow: #15afd040 0px 20px 100px 0px;*/
}

.hovercard:hover {
  transform: scale(1.025);
  /*box-shadow: #15afd066 0px 20px 100px 0px;*/
  /*box-shadow: 0px 0px 50px 0px #15afd0;*/
  /*box-shadow: 0px 25px 50px -12px;*/
  /*outline: 0.2px solid #15afd0;*/
  z-index: 4001;
  cursor: pointer;
  overflow: hidden;
}

.ant-card-meta-title {
  color: #daf1ff !important;
}

.ant-card-meta-description {
  color: #15afd0 !important;
  height: 70px;
  overflow-x: auto;
  line-height: 1.5;
  margin-top: 0.25rem;
}

.select-link {
  opacity: 0.7;
}

.select-link:hover {
  cursor: pointer;
  color: #15afd0;
  opacity: 1;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 0 0 !important;
  margin-right: 10px !important;
}

.ant-tabs-tab {
  color: grey !important;
  background: initial !important;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  color: #15afd0 !important;
  background: #181b20 !important;
}

.ant-tabs-nav {
  margin: none !important;
}

.cust-carosel-section {
  background-color: rgba(255, 255, 255, 0.03) !important;
  text-align: center !important;
  height: 125px !important;
  cursor: pointer !important;
}

.cust-carosel-section-second {
  text-align: center !important;
  height: 125px !important;
  cursor: pointer !important;
}

.cust-carosel-section-selected {
  background-color: linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148)) !important;
  text-align: center !important;
  height: 125px !important;
  cursor: pointer;
}

.card-section:hover {
  /* background: linear-gradient(to right, rgb(21, 175, 208), rgb(18, 125, 148)) !important; */
  background-color: rgba(21, 175, 208, 0.2) !important;
}

/* .outerSection:hover {
  border: 1px solid #167b92 !important;
  border-radius: 10px;
}

.outerSection2:hover {
  border: 1px solid #167b92 !important;
  border-radius: 10px;
} */


.outerSection .ant-input {
  border: 1px solid transparent !important;
  background-color: rgba(255, 255, 255, 0.02);
}

.outerSection .a-icon-0-2-72 {
  margin-left: 33px !important;
}


.outerSection .ant-input:focus,
.ant-input-focused {
  border-color: 1px solid transparent !important;
  outline: none;
  box-shadow: none;
}

.outerSection .ant-input-number-lg {
  height: 66px !important;
  width: 540px !important;
  border: 1px solid transparent !important;
  background-color: rgba(255, 255, 255, 0.02);
}

.outerSection .ant-input-number-lg:hover {
  border: 1px solid transparent !important;
  background-color: rgba(255, 255, 255, 0.02);
}

.set-objective .ant-modal-content {
  max-height: 690px;
  overflow-y: auto;
}

.set-objective .ant-btn-background-ghost.ant-btn-primary {
  color: gray;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.outerSection .ant-input-number-input {
  border: 1px solid transparent !important;
  background-color: rgba(255, 255, 255, 0.02) !important;
}

.outerSection .ant-input-number-input:focus,
.ant-input-focused {
  border-color: 1px solid transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.list-section .ant-checkbox-inner {
  border-color: #fff !important;
}

.list-section .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
}

.cust-pie-chart-section .recharts-surface text {
  font-size: 12px;
}

.labelDescription-cs{
  background: none !important;
  /* margin: 0px 0px 0px 10px ; */
}

.pie-labels-section {
  text-align: center !important;
  justify-content: center !important;
  align-items: center;
}

.outerSection .ant-input-number-input:hover {
  border: 1px solid transparent !important;
  background-color: rgba(255, 255, 255, 0.02) !important;
}

.outerSection .anticon svg {
  margin-left: 30px !important;
}

.custom-rule-builder .anticon svg{
  margin-left: 0px !important;
}

.outerSection2 .anticon svg {
  margin: 10px !important;
}