.comparision-table-section
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  background: #181b20;
}

.comparision-table-section td.ant-table-cell {
  border-top: 1px solid #ffffff12 !important;
  border-bottom: 1px solid #ffffff12 !important;
  border-left: 1px solid #ffffff12 !important;
  border-right: 1px solid #ffffff12 !important;
  padding: 8px !important;
  /* font-size: 14px; */
}

.comparision-table-section th.ant-table-cell {
  color: #15afd0 !important;

  border-bottom: 2px solid #ffffff12 !important;
  border-left: 2px solid #ffffff12 !important;
  text-align: center !important;
  padding: 8px !important;
}

.comparision-table-section .ant-table table {
  border-radius: 0px !important;
}

.table-row-light {
  background-color: #414a4c;
  color: rgba(255, 255, 255, 0.66);
  background: rgba(255, 255, 255, 0.02) !important;
}

.table-row-dark {
  color: rgba(255, 255, 255, 0.66) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.table-row-light-clickable {
  background-color: #414a4c !important;
  color: rgba(255, 255, 255, 0.66);
  background: rgba(255, 255, 255, 0.02) !important;
  cursor: pointer;
}

.table-row-dark-clickable {
  color: rgba(255, 255, 255, 0.66) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  cursor: pointer;
}

.table-row-highlighted-clickable {
  color: rgba(255, 255, 255, 0.84) !important;
  background-color: rgb(18, 125, 148) !important;
  cursor: pointer;
}

.comparision-table-section .ant-table-container .ant-table-body {
  overflow: auto hidden !important;
}

.comparision-table-section .ant-table-sticky-holder {
  top: -20px !important;
}

.comparision-table-section .ant-table-sticky-scroll {
  display: none;
}

.comparision-table-section .ant-btn-icon-only {
  z-index: 10;
}
