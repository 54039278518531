.test-form-custom-div .ant-steps-item-active .ant-steps-item-title {
  color: #15afd0 !important;
  font-weight: 600;
  font-size: 15px;
}

.target-list-custom-wrapper {
  cursor: pointer;
}

.target-list-custom-wrapper:hover {
  background-color: #18434f;
  border-radius: 5px;
}

.active-target-list-custom-wrapper {
  background-color: rgba(24, 67, 79, 0.3);
  border-radius: 5px;
}

/* .test-form-custom-div .ant-tag {
  background: rgb(0, 0, 0);
  color: rgb(21, 175, 208);
  margin-right: 5 px;
  border: 1 px solid rgb(21, 175, 208);
  font-size: 12px;
} */

.custom-action-elements {
  cursor: pointer;
  height: 40px !important;
  width: auto;
  margin: 10px 20px;
  background-color: rgba(255, 255, 255, 0.04) !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.test-form-custom-div .steps-content {
  height: 85vh !important;
}

.test-form-custom-new-div .steps-content {
  height: 81vh !important;
}

.test-form-custom-div .custom-panel {
  background-color: transparent;
}

.querybuilder-custom-wrapper .ant-btn-ghosted {
  color: #0988ab;
  border-color: #0988ab;
}

.custom-site-form-item-icon {
  color: #0988ab !important;
  margin-right: 2px !important;
}

.custom-form-element-weighted .ant-form-item {
  flex-direction: column;
  align-items: flex-start;
}

.custom-form-element-weighted label {
  color: #0988ab !important;
  padding-left: 2px;
}

.add-experiment-card-section .ant-card {
  /* background-color: #111419;
  border: 1px solid rgba(255,255,255,0.2); */
  /* margin-top: 20px; */
}

.row-section-finalize:hover {
  background-color: #18434f;
}

.row-section-finalize .ant-divider-horizontal {
  margin: 0px;
}

.card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-ant-card-no-padding .ant-card-body {
  padding: 0px;
  /* margin-bottom: 20px; */
}

.advanced-config-modal .ant-modal-footer {
  display: none;
}

.advanced-config-modal .ant-modal-body {
  overflow-y: auto !important;
  background: rgb(24, 27, 32) !important;
}
